import { Store } from 'vuex'
import { AuthAPI, useAuthAPI } from './auth'

export interface API extends AuthAPI {
  sendRequest: (path: string, method?: string, body?: BodyInit) => Promise<Response>,
  sendSpaAPI: (path: string, method?: string, body?: BodyInit) => Promise<Response>,
  checkAndParse: (response: Response) => Promise<any | Error>
}
export function getHeaders (): {
  Accept: string
  } {
  return {
    Accept: 'application/json'
  }
}

export function useAPI (storeRoot: Store<any>): API {
  const authAPI = useAuthAPI(storeRoot)
  const checkAndParse = async (response: Response): Promise<any | Error> => {
    if (response.ok)
      return await response.json()
    if (response.status === 401)
      await authAPI.logout(true)
    throw new Error(response.statusText)
  }
  const sendRequest = async (path: string, method = 'GET', body?: BodyInit): Promise<Response> => {
    let headers = { 'X-XSRF-TOKEN': storeRoot.getters['auth/getToken'] }
    if (method === 'POST') {
      await authAPI.getCSRF()
      headers = authAPI.withXSRF()
    }
    const response = await fetch(`${process.env.VUE_APP_API_URL}/${path}`, {
      method,
      body,
      headers: Object.assign(headers, getHeaders())
    })
    if (response.status === 401)
      authAPI.logout(true)
    if (response.ok)
      return response
    else
      throw new Error((await response.json()).errors)
  }
  const sendSpaAPI = async (path: string, method = 'GET', body?: BodyInit): Promise<Response> =>
    sendRequest(`spa-api/${path}`, method, body)

  return {
    sendRequest,
    sendSpaAPI,
    checkAndParse,
    ...authAPI
  }
}
