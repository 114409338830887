import { createStore } from 'vuex'
import createCache from 'vuex-cache'
import createPersistedState from 'vuex-persistedstate'
import auth from './modules/auth'
import price from './modules/price'
import users from './modules/users'
import offer from './modules/offer'
import product from './modules/product'
import company from './modules/company'
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    offer,
    price,
    users,
    product,
    company
  },
  plugins: [
    createCache(),
    createPersistedState()
  ]
})
