import { UserPosition } from '@/interfaces/models/company/department/user_position'
import { sendRequest } from '@/mixins/store/api'

interface UPState {
  listConcrete: Array<UserPosition>,
  selectedPosition: UserPosition
}

export default {
  namespaced: true,
  state: {
    listConcrete: [] as Array<UserPosition>,
    selectedPosition: null
  },
  mutations: {
    setListConcrete: (state: UPState, listConcrete: Array<UserPosition>): Array<UserPosition> => (state.listConcrete = listConcrete),
    setSelectedPosition: (state: UPState, position: UserPosition): UserPosition => (state.selectedPosition = position)
  },
  getters: {
    listConcrete: (state: UPState): Array<UserPosition> => state.listConcrete,
    selectedPosition: (state: UPState): UserPosition => state.selectedPosition
  },
  actions: {
    getListConcrete (context: any): Promise<Array<UserPosition>> {
      if (context.getters.listConcrete.length !== 0)
        return context.getters.listConcrete
      return sendRequest(context, 'company/department/user-position/all', (json: {
        positions: Array<UserPosition>
      }) => {
        context.commit('setListConcrete', json.positions)
        return json.positions
      })
    }
  }
}
