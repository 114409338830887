import { Product } from '@/interfaces/models/company/department/product'
import { Part } from '@/interfaces/models/company/department/product/part'
import { Price } from '@/interfaces/models/price'
import { sendRequest } from '@/mixins/store/api'

interface ProductsState {
  listConcrete: Array<Product>
}

export default {
  namespaced: true,
  state: {
    listConcrete: [] as Array<Product>
  },
  mutations: {
    setListConcrete (state: ProductsState, listConcrete: Array<Product>): void {
      state.listConcrete = listConcrete
    },
    setProduct (state: ProductsState, product: Product): void {
      state.listConcrete = state.listConcrete.map((_product) => _product.id === product.id ? product : _product)
    }
  },
  getters: {
    listConcrete: (state: ProductsState): Array<Product> => state.listConcrete
  },
  actions: {
    getListConcrete (context: any, payload: { departmentId: number }): Promise<Array<Product>> {
      if (context.getters.listConcrete.length > 0)
        return context.getters.listConcrete
      return sendRequest(context, `company/department/product/all/${payload.departmentId}`, (json: { products: Array<Product> }) => {
        context.commit('setListConcrete', json.products)
        return json.products
      })
    },
    store (context: any, products: Array<Product>): Promise<Array<Product>> {
      return sendRequest(context, {
        path: 'company/department/product/store',
        method: 'POST',
        bodyInit: JSON.stringify(products)
      }, (json: { products: Array<Product> }) => {
        context.commit('setListConcrete', [...context.getters.listConcrete, ...json.products])
        return json.products
      })
    },
    changePrice (context: any, payload: { productId?: number, partId: number, value: number }): Promise<Part> {
      const formData = new FormData()
      formData.append('value', payload.value.toString())
      return sendRequest(context, {
        path: `product/part/change-price/${payload.partId}`,
        method: 'POST',
        bodyInit: formData
      }, ({ price }: { price: Price }) => {
        context.commit('price/pushToPrices', [price], { root: true })
        let part: Part|null = null
        const product: Product | undefined = context.getters.listConcrete.find((_product: Product) => {
          if (typeof payload.productId !== 'undefined')
            return _product.id === payload.productId
          const finded = _product.parts!.find(_part => _part.id === payload.partId)
          part = finded || null
          return Boolean(part)
        })
        if (part !== null)
          part!.price = price
        else
          product!.parts = product!.parts!.map(_part => {
            if (_part.id === payload.partId) {
              part = _part
              _part.price = price
            }
            return _part
          })
        return part
      })
    }
  }
}
