import product from './product'
import userPosition from './user_position'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {
  },
  modules: {
    product,
    userPosition
  }
}
