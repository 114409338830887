import { getCookie } from '@/libs/cookie'
import { Store } from 'vuex'
import { getHeaders } from './'
export interface AuthAPI {
  login: (email: string, password: string, remember?: boolean) => Promise<Response>,
  logout: (logic?: boolean) => Promise<Response | void>,
  getCSRF: () => Promise<void>,
  withXSRF: () => { 'X-XSRF-TOKEN': string },
}
function clearStore (store: Store<any>) {
  store.commit('company/department/product/setListConcrete', [], { root: true })
  store.commit('company/department/userPosition/setListConcrete', [], { root: true })
  store.commit('offer/position/variable/clear', undefined, { root: true })
  store.commit('offer/note/clear', undefined, { root: true })
  store.commit('price/clear', undefined, { root: true })
  store.commit('offer/clear', [], { root: true })
}
export function useAuthAPI (storeRoot: Store<any>): AuthAPI {
  const host = process.env.VUE_APP_API_URL
  const withXSRF = () => Object.assign({ 'X-XSRF-TOKEN': storeRoot.getters['auth/getToken'] }, getHeaders())
  const getCSRF = async (): Promise<void> => await fetch(`${host}/sanctum/csrf-cookie`, { credentials: 'same-origin' })
    .then(() => storeRoot.commit('auth/setToken', getCookie('XSRF-TOKEN'), { root: true }))

  const login = async (email: string, password: string, remember = false): Promise<Response> => {
    if (!storeRoot.getters['auth/getAuthStatus']) {
      await getCSRF()
      const formData = new FormData()
      formData.append('email', email)
      formData.append('password', password)
      formData.append('remember', String(remember))
      const response = await fetch(`${host}/login`, {
        method: 'POST',
        body: formData,
        headers: withXSRF()
      })
      if (response.ok)
        storeRoot.commit('auth/setAuthStatus', true)
      return response
    }
    throw new Error('Вы уже вошли в аккаунт')
  }
  const logout = async (logic = false): Promise<Response | void> => {
    clearStore(storeRoot)
    if (logic && storeRoot.getters['auth/getAuthStatus'])
      return storeRoot.commit('auth/setAuthStatus', false, { root: true })

    if (storeRoot.getters['auth/getAuthStatus']) {
      await getCSRF()
      const response = await fetch(`${host}/logout`, {
        method: 'POST',
        headers: withXSRF()
      })
      if (response.ok) {
        storeRoot.commit('auth/setAuthStatus', false, { root: true })
        storeRoot.commit('users/logout')
      }
      return response
    }
    throw new Error('Вы не авторизованы')
  }

  return {
    login,
    logout,
    getCSRF,
    withXSRF
  }
}
