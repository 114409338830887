import { Type } from '@/interfaces/models/product/type'
import { sendRequest } from '@/mixins/store/api'

interface TypeState {
  types: Array<Type>
}
export default {
  namespaced: true,
  state: {
    types: [] as Array<Type>
  },
  mutations: {
    pushToTypes ({ types }: TypeState, _types: Array<Type>): void {
      for (const _type of _types)
        if (types.findIndex((type) => type.id === _type.id) === -1)
          types.push(_type)
    },
    clear (state: TypeState): void {
      state.types = []
    }
  },
  getters: {
    allTypes: (state: TypeState): Array<Type> => state.types
  },
  actions: {
    allTypes (context: any): Promise<Array<Type>> {
      if (context.getters.allTypes.length > 0)
        return Promise.resolve(context.getters.allTypes)
      return sendRequest(context, 'product/type/all', ({ types }: { types: Array<Type> }) => {
        context.commit('pushToTypes', types)
        return types
      })
    }
  }
}
