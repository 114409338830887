import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import PrimeVue from 'primevue/config'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import ToastService from 'primevue/toastservice'
import Chip from 'primevue/chip'
import Toast from 'primevue/toast'
import Button from 'primevue/button'
import Sidebar from 'primevue/sidebar'
import Tooltip from 'primevue/tooltip'
import Calendar from 'primevue/calendar'
import Checkbox from 'primevue/checkbox'
import Textarea from 'primevue/textarea'
import InputText from 'primevue/inputtext'
import SpeedDial from 'primevue/speeddial'
import InputNumber from 'primevue/inputnumber'
import RadioButton from 'primevue/radiobutton'
import InputSwitch from 'primevue/inputswitch'
import AutoComplete from 'primevue/autocomplete'
import InlineMessage from 'primevue/inlinemessage'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'

const app = createApp(App).use(store).use(router).use(PrimeVue).use(ToastService)
app.component('Chip', Chip)
app.component('Toast', Toast)
app.component('Button', Button)
app.component('Sidebar', Sidebar)
app.component('Calendar', Calendar)
app.component('Checkbox', Checkbox)
app.component('Textarea', Textarea)
app.component('InputText', InputText)
app.component('SpeedDial', SpeedDial)
app.component('InputNumber', InputNumber)
app.component('RadioButton', RadioButton)
app.component('InputSwitch', InputSwitch)
app.component('AutoComplete', AutoComplete)
app.component('InlineMessage', InlineMessage)

app.directive('tooltip', Tooltip)

app.mount('#app')
