import { User } from '@/interfaces/models/user'

interface UsersState {
  users: Array<User>,
  selectedIndex: number
}

export default {
  namespaced: true,
  state: {
    users: [],
    selectedIndex: -1
  },
  mutations: {
    add (state: UsersState, user: User): void {
      if (state.users.find(_user => _user.id === user.id) === undefined) {
        state.users.push(user)
        state.selectedIndex = state.users.length - 1
      }
    },
    remove (state: UsersState, id: number | boolean): void {
      if (id === true)
        id = state.selectedIndex
      state.users = state.users.filter((_user, index) => {
        const result = _user.id !== id
        if (result && index === state.selectedIndex)
          state.selectedIndex = -1
        return result
      })
    },
    logout (state: UsersState): void {
      state.selectedIndex = -1
    }
  },
  getters: {
    users (state: UsersState): Array<User> {
      return state.users
    },
    currentUser (state: UsersState): User | null {
      return state.selectedIndex > -1 ? state.users[state.selectedIndex] : null
    }
  }
}
