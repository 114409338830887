interface AuthState {
  status: boolean,
  xsrfToken: string
}

export default {
  namespaced: true,
  state: {
    status: false,
    xsrfToken: ''
  },
  mutations: {
    setAuthStatus (state: AuthState, status: boolean): void {
      state.status = status
    },
    setToken (state: AuthState, token: string): void {
      state.xsrfToken = token
    }
  },
  getters: {
    getAuthStatus (state: AuthState): boolean {
      return state.status
    },
    getToken (state: AuthState): string {
      return state.xsrfToken
    }
  }
}
