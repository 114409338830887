import department from './department'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {
  },
  modules: {
    department
  }
}
