import variable from './variable'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {
  },
  modules: {
    variable
  }
}
