import { useAPI } from '@/composables/api'
import { Store } from 'vuex'

export interface RequestParams {
  path: string,
  method?: 'GET' | 'POST',
  bodyInit?: BodyInit
}

export function buildRootContext ({ dispatch, commit, ...rest }: any): Store<any> {
  return {
    getters: rest.rootGetters,
    dispatch,
    commit
  } as Store<any>
}
export async function sendRequest (context: any, pathOrParams: string | RequestParams, closure: (json: any) => any): Promise<any> {
  const api = useAPI(buildRootContext(context))
  const response = typeof pathOrParams === 'string'
    ? api.sendSpaAPI(pathOrParams)
    : api.sendSpaAPI(pathOrParams.path, pathOrParams.method, pathOrParams.bodyInit)
  return await response.then(api.checkAndParse).then(closure)
}
