import { Price } from '@/interfaces/models/price'
import { sendRequest } from '@/mixins/store/api'

interface PriceState {
  prices: Array<Price>
}
export default {
  namespaced: true,
  state: {
    prices: [] as Array<Price>
  },
  mutations: {
    pushToPrices ({ prices }: PriceState, _prices: Array<Price>): void {
      for (const _price of _prices)
        if (prices.findIndex((price) => price.id === _price.id) === -1)
          prices.push(_price)
    },
    clear (state: PriceState): void {
      state.prices = []
    }
  },
  getters: {
    allPrices: (state: PriceState): Array<Price> => state.prices
  },
  actions: {
    allPrices (context: any): Promise<Array<Price>> {
      if (context.getters.allPrices.length > 0)
        return Promise.resolve(context.getters.allPrices)
      return sendRequest(context, 'price/all', ({ prices }: { prices: Array<Price> }) => {
        context.commit('pushToPrices', prices)
        return prices
      })
    }
  }
}
