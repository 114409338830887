import position from './position'
import note from './note'
import { CommercialOffer } from '@/interfaces/models/offer'
import { sendRequest } from '@/mixins/store/api'

interface OfferState {
  offers: Array<CommercialOffer>
}

export default {
  namespaced: true,
  state: {
    offers: []
  },
  mutations: {
    setList (state: OfferState, list: Array<CommercialOffer>): void {
      state.offers = list
    },
    add (state: OfferState, offer: CommercialOffer): void {
      if (state.offers.find(_offer => _offer.id === offer.id) === undefined)
        state.offers.push(offer)
    },
    clear (state: OfferState): void {
      state.offers = []
    }
  },
  getters: {
    offers (state: OfferState): Array<CommercialOffer> {
      return state.offers
    }
  },
  actions: {
    getOffers (context: any): Promise<Array<CommercialOffer>> {
      if (context.getters.offers.length > 0)
        return context.getters.offers
      return sendRequest(context, 'offer', ({ offers }: { offers: Array<CommercialOffer> }) => {
        context.commit('setList', offers)
        return offers
      })
    }
  },
  modules: {
    position,
    note
  }
}
