import { Part as ExpressionPart, ExpressionBlock } from '@/interfaces/models/offer/position/variable/expression_block'
import { sendRequest } from '@/mixins/store/api'

interface VariableState {
  expressions: Array<ExpressionBlock>
}
export default {
  namespaced: true,
  state: {
    expressions: [] as Array<ExpressionBlock>
  },
  mutations: {
    pushToExpressions ({ expressions }: VariableState, varsBlock: ExpressionBlock): void {
      if (expressions.findIndex((block) => block.priceId === varsBlock.priceId) === -1)
        expressions.push(varsBlock)
    },
    clear (state: VariableState): void {
      state.expressions = []
    }
  },
  getters: {
    expressions: (state: VariableState): Array<ExpressionBlock> => state.expressions
  },
  actions: {
    getExpressionByPrice (context: any, priceId: number): Promise<ExpressionBlock> {
      const block = context.getters.expressions.find((block: ExpressionBlock) => block.priceId === priceId)
      if (block)
        return Promise.resolve(block)
      return sendRequest(context, 'offer/position/variable/expression-by-price/' + priceId, ({ parts }: { parts: Array<ExpressionPart> }) => {
        const block: ExpressionBlock = { priceId, parts }
        context.commit('pushToExpressions', block)
        return block
      })
    }
  }
}
