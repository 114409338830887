import { useAPI } from '@/composables/api'
import { UserPosition } from '@/interfaces/models/company/department/user_position'
import { Note } from '@/interfaces/models/offer/note'
import { buildRootContext, sendRequest } from '@/mixins/store/api'

interface NoteState {
  notes: Array<Note>
}
export default {
  namespaced: true,
  state: {
    notes: [] as Array<Note>
  },
  mutations: {
    pushToNotes ({ notes }: NoteState, newNotes: Array<Note>): void {
      notes.push(...newNotes.filter((newNote) => notes.findIndex((note) => note.id === newNote.id) === -1))
    },
    clear (state: NoteState): void {
      state.notes = []
    }
  },
  getters: {
    notes: (state: NoteState): Array<Note> => state.notes
  },
  actions: {
    getByText (context: any, text: string): Promise<Array<Note>> {
      const notes = context.getters.notes.filter((note: Note) => note.text.toLowerCase().indexOf(text.toLowerCase()) !== -1) as Array<Note>
      if (notes.length > 5)
        return Promise.resolve(notes)
      return sendRequest(context, 'offer/note/notes-by-text/' + text, ({ notes }: { notes: Array<Note> }) => {
        context.commit('pushToNotes', notes)
        return notes
      })
    },
    mostPopular (context: any, count: number | undefined): Promise<Array<Note>> {
      return sendRequest(context, 'offer/note/most-popular/' + (count === undefined ? '' : count), ({ notes }: { notes: Array<Note> }) => {
        context.commit('pushToNotes', notes)
        return notes
      })
    },
    add (context: any, note: Note): boolean {
      if (!context.getters.notes.find(
        (_note: Note) => _note.id === note.id ||
          _note.text === note.text)
      ) {
        context.commit('pushToNotes', [note])
        return true
      }
      return false
    },
    store (context: any, note: Note): Promise<Note> {
      const userPosition = context.rootGetters['company/department/userPosition/getSelectedPosition'] as UserPosition
      const api = useAPI(buildRootContext(context))
      const formData = new FormData()
      formData.append('text', note.text)
      formData.append('position_id', userPosition.id!.toString())
      return api.sendSpaAPI('offer/note/store', 'POST', formData)
        .then(api.checkAndParse).then(({ note }: { note: Note }) => {
          context.commit('pushToNotes', [note])
          return note
        })
    }
  }
}
